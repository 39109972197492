
/* Títulos */
.termsOfSee {
h1, h2, h3, h4, h5, h6 {
  color: #333;
  font-weight: bold;
  margin-bottom: 10px;
  font-family: Arial, sans-serif;
}

h1 {
  font-size: 18px;
}

h2 {
  font-size: 18px;
}

h3 {
  font-size: 16px;
}

h4 {
  font-size: 14px;
}

h5 {
  font-size: 12px;
}

h6 {
  font-size: 10px;
}

/* Parágrafos */

p {
  margin-bottom: 20px;
}

p:first-letter {
  font-weight: bold;
  font-size: 130%;
}

/* Listas */

ul {
  list-style-type: disc;
  margin-left: 20px;
  padding: 0;
}

ol {
  list-style-type: decimal;
  margin-left: 20px;
  padding: 0;
}

li {
  margin-bottom: 10px;
}

/* Links */

a {
  text-decoration: none;
  color: #00698f;
}

a:hover {
  text-decoration: underline;
  color: #007bff;
}

/* Tabelas */

table {
  border-collapse: collapse;
  width: 100%;
}

th, td {
  border: 1px solid #ddd;
  padding: 10px;
}

th {
  background-color: #f0f0f0;
  font-weight: bold;
}

td {
  background-color: #fff;
}}